import { fallDown as Menu } from 'react-burger-menu'
import React from 'react';
import './sidebar.css';
import { Link } from 'react-router-dom';
import DictPlusPlusLogo from './img/Logo dictionary plusplus 2.png'

function refreshPage(){
  setTimeout(()=>{
    window.location.reload(false);
  }, 500);
 
}


class Sidebar extends React.Component {

   


  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (        
      // <Menu itemListElement="div"  Menu isOpen={ false } customCrossIcon ={ <img src={LogoReact} width="100%" height="100%" alt="kkfsfskdfskd" /> }  customBurgerIcon={ <img src={LogoReact} width="100%" height="100%" alt="kkfsfskdfskd" /> } width={ '280px' } pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }    >

      

      <Menu right width={ '320px' } pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }    >

      <div id="outer-container">
        
            <div className="text-center">
              <img src={DictPlusPlusLogo} width="50%" height="50%" alt="DictionaryPlusPlus.my.id" />
                <h1 >
                    <Link style={{WebkitTextFillColor:'rgb(255, 81, 0)', fontFamily: 'Georgia', fontSize: '30px'}} to="/" className="nav-link" onClick={refreshPage}>
                          Dictionary Plus+
                    </Link>
                </h1>
                      <small style={{WebkitTextFillColor: 'rgb(200, 70, 0)'}}>
                          tempat belajar berbagai hal
                      </small>
                                  
                
            </div>
            <br/>
            <br/>
                
            <nav >
              <div className="text-center">
                                    
                        <Link to="/" className="nav-link" onClick={refreshPage}>
                        <span>Home</span>
                        </Link>
                      <br/>
                      
                        <Link to="/codeIgniter-4" className="nav-link" onClick={refreshPage}>
                            <span>CodeIgniter 4</span>
                        </Link>
                      <br/>
                      
                        <Link to="/unity-3D" className="nav-link" onClick={refreshPage}>
                        <span>Unity 3D</span>
                        </Link>
                      <br/>
                      
                        <Link to="/corel-draw" className="nav-link" onClick={refreshPage}>
                        <span>Corel Draw X6</span>
                        </Link>
                      <br/>
                      
                        <Link to="/microsoft-office-excel-2010" className="nav-link" onClick={refreshPage}>
                        <span>Microsoft Office Excel 2010</span>
                        </Link>
                      <br/>
                      
                        <Link to="/seputar-aplikasi-android" className="nav-link" onClick={refreshPage}>
                        <span>Seputar Aplikasi Android</span>
                        </Link>
                      <br/>

                        <Link to="/pengetahuan-umum" className="nav-link" onClick={refreshPage}>
                        <span>Pengetahuan Umum</span>
                        </Link>
                      <br/>
                  {/* </ul> */}
              </div>
            </nav>

          <main id="page-wrap"></main>

        </div>

      </Menu>

    



      
    );
  }
}

export default Sidebar;